import consumer from "./consumer";

consumer.subscriptions.create("StockImportProgressBarChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const divText = $(
      "#" + data.env + "_progress_bar_" + data.process + "_text"
    );
    const divStatus = $(
      "#" + data.env + "_progress_bar_" + data.process + "_status"
    );
    const percentage = data.percentage;
    const progressDiv = "#stock_sh_progress_bar";
    if (percentage === 100) {
      $(progressDiv).hide();
    } else {
      $(progressDiv).show();
    }
    divStatus.width(percentage + "%");
    divText.text(percentage + "%");
    window.localStorage.setItem(
      data.env + "_progress_bar_" + data.process,
      percentage
    );
  },
});
